/**
 * Options available for selection for prospective members
 * (they are mapped before sending to backend)
 */
export enum TreatmentProgram {
  Alcohol = 'alcohol',
  Cannabis = 'cannabis',
  ChewingTobacco = 'chewingTobacco',
  Cigarettes = 'cigarettes',
  eCigarettes = 'eCigarettes',
  Opioids = 'opioids',
  CigarettesLearn = 'cigarettesLearn',
  eCigarettesLearn = 'eCigarettesLearn',
  Stimulants = 'stimulants',
}
// A configuration object to represent whether a treatment program is enabled
export type TreatmentProgramConfig = Record<TreatmentProgram, boolean>

// Quitting is used for communication with BE
export type Quitting = Exclude<
  TreatmentProgram,
  | TreatmentProgram.CigarettesLearn
  | TreatmentProgram.eCigarettesLearn
  | TreatmentProgram.ChewingTobacco
>

export type TobaccoSubAddictions =
  | TreatmentProgram.ChewingTobacco
  | TreatmentProgram.Cigarettes
  | TreatmentProgram.eCigarettes
  | TreatmentProgram.CigarettesLearn
  | TreatmentProgram.eCigarettesLearn

export const ADDICTIONS = Object.values(TreatmentProgram)

export const TOBACCO_SUB_ADDICTIONS = [
  TreatmentProgram.ChewingTobacco,
  TreatmentProgram.Cigarettes,
  TreatmentProgram.eCigarettes,
  TreatmentProgram.CigarettesLearn,
  TreatmentProgram.eCigarettesLearn,
]

const TOBACCO_MIN_AGE_THRESHOLD = 15
export const MINOR_PROGRAM_MIN_AGE_THRESHOLDS: Record<
  TreatmentProgram,
  number
> = {
  [TreatmentProgram.Alcohol]: 15,
  [TreatmentProgram.Cannabis]: 15, // TODO: [COPS-1398] Confirm age.
  [TreatmentProgram.ChewingTobacco]: TOBACCO_MIN_AGE_THRESHOLD,
  [TreatmentProgram.Cigarettes]: TOBACCO_MIN_AGE_THRESHOLD,
  [TreatmentProgram.eCigarettes]: TOBACCO_MIN_AGE_THRESHOLD,
  [TreatmentProgram.CigarettesLearn]: TOBACCO_MIN_AGE_THRESHOLD,
  [TreatmentProgram.eCigarettesLearn]: TOBACCO_MIN_AGE_THRESHOLD,
  [TreatmentProgram.Opioids]: 16,
  [TreatmentProgram.Stimulants]: 16, // TODO: [COPS-1398] Confirm age.
}

export interface WorkingQuittingPriorityItem {
  quitting: TreatmentProgram
  ageAtEnrolment?: number
  state?: string
}

/**
 * WorkingQuittingPriority that has to be transformed before sending to backend (e.g. chewingTobacco, "learn" programs)
 */
export interface WorkingQuittingPriority {
  [key: number]: WorkingQuittingPriorityItem
}

export enum CallType {
  OnboardingCall = 'onboarding-call',
  CoachingCall = 'coaching-call',
  PrescriberCall = 'prescriber-call',
  PrescriberTelemedCall = 'prescriber-telemed-call',
  PrescriberStateCall = 'prescriber-state-call',
  CareCoordinator = 'care-coordinator',
}
