import { baseCompanyConfigs } from './companies'
import { Company } from './schema'

const allCompanies: Company[] = baseCompanyConfigs
const enabledCompanies: Company[] = allCompanies.filter((c) => c.enabled)

interface CompanyService {
  readonly allCompanies: Company[]
  readonly enabledCompanies: Company[]
  getById(id: number): Company | undefined
}

const companyService: CompanyService = {
  allCompanies,
  enabledCompanies,
  getById: (id) => enabledCompanies.find((c) => c.id === id),
}

export default Object.freeze(companyService)
export type { Company }
