import { FlowVersion } from 'flow-version'

import { Country, DataRegion } from '~constants/types'

import { Company } from './schema'

export const baseCompanyConfigs: Company[] = [
  {
    id: 1,
    enabled: true,
    name: 'Sequoia',
    slug: 'sequoiaquit',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 4,
    enabled: true,
    name: 'Corning',
    slug: 'corning',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 5,
    enabled: true,
    name: 'Chegg',
    slug: 'chegg',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 9,
    enabled: true,
    name: 'GE Appliances',
    slug: 'gea',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 10,
    enabled: true,
    name: 'My Care Alabama',
    slug: 'mycarealabama',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 11,
    enabled: true,
    name: 'Demo',
    slug: 'demo',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 12,
    enabled: true,
    name: 'Mortenson',
    slug: 'mortenson',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: true,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 13,
    enabled: true,
    name: 'All Stars',
    slug: 'allstars',
    dataRegion: DataRegion.EU,
    country: Country.GB,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
  },
  {
    id: 16,
    enabled: true,
    name: 'Yokohama',
    slug: 'yokohama',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 20,
    enabled: true,
    name: 'All Stars And Stripes',
    slug: 'allstarsandstripes',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: true,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: true,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    digitalOnboardingEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
      [FlowVersion.HealthPlan]: true,
    },
  },
  {
    id: 21,
    enabled: true,
    name: 'Husqvarna',
    slug: 'husqvarna',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 22,
    enabled: true,
    name: 'HPE',
    slug: 'hpe',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 24,
    enabled: true,
    name: 'Beta',
    slug: 'beta',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 26,
    enabled: true,
    name: 'K&L Gates',
    slug: 'klgates',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 27,
    enabled: true,
    name: 'Express Scripts',
    slug: 'expressScripts',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false, // Consider ESI eligibility service if changing: https://quitgenius.atlassian.net/browse/COPS-1307?focusedCommentId=56610
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false, // Consider ESI eligibility service if changing: https://quitgenius.atlassian.net/browse/COPS-1307?focusedCommentId=56610
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 28,
    enabled: true,
    name: 'Beacon Health',
    slug: 'beacon',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 29,
    enabled: true,
    name: 'Tractor Supply Company',
    slug: 'tsc',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 30,
    enabled: true,
    name: 'Rice University',
    slug: 'rice',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: true,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 33,
    enabled: true,
    name: 'Silgan Containers',
    slug: 'silgan',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 34,
    enabled: true,
    name: 'WEI',
    slug: 'wei',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 35,
    enabled: true,
    name: 'Carters',
    slug: 'carters',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
  },
  {
    id: 36,
    enabled: true,
    name: 'Philips',
    slug: 'philips',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 37,
    enabled: true,
    name: 'Valmont',
    slug: 'valmont',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 38,
    enabled: true,
    name: 'Opioids Research Study',
    slug: 'opioidsresearchstudy',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 39,
    enabled: true,
    name: 'Compsych',
    slug: 'compsych',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 40,
    enabled: true,
    name: 'Discovery Vitality',
    slug: 'discoveryvitality',
    dataRegion: DataRegion.EU,
    country: Country.ZA,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: true,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 41,
    enabled: true,
    name: 'The Cheesecake Factory',
    slug: 'cheesecake',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 42,
    enabled: true,
    name: 'Securitas',
    slug: 'securitas',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 43,
    enabled: true,
    name: 'MPI-Tobacco',
    slug: 'mpi',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 44,
    enabled: true,
    name: 'Garmin',
    slug: 'garmin',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 45,
    enabled: true,
    name: 'Evry Health',
    slug: 'evryhealth',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 46,
    enabled: true,
    name: 'My Care Alabama Northwest',
    slug: 'mcanorthwest',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 48,
    enabled: true,
    name: 'Lumen',
    slug: 'lumen',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 49,
    enabled: true,
    name: 'Dollar Tree',
    slug: 'dollar',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 50,
    enabled: true,
    name: 'Yanfeng',
    slug: 'yanfeng',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 51,
    enabled: true,
    name: 'Cancer Treatment Centers of America',
    slug: 'ctca',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 52,
    enabled: true,
    name: 'Alcohol Study A Group',
    slug: 'alcohol-study-a',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 53,
    enabled: true,
    name: 'Alcohol Study B Group',
    slug: 'alcohol-study-b',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 54,
    enabled: true,
    name: 'Hubbell',
    slug: 'hubbell',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 55,
    enabled: true,
    name: 'AT&T',
    slug: 'att',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: true,
      eCigarettesLearn: true,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 56,
    enabled: true,
    name: 'BT&T',
    slug: 'btt',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: true,
      eCigarettesLearn: true,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 57,
    enabled: true,
    name: 'Schnuck Markets',
    slug: 'schnucks',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 58,
    enabled: true,
    name: 'Orano',
    slug: 'orano',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 59,
    enabled: true,
    name: 'Appalachian Regional Healthcare',
    slug: 'arh',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 60,
    enabled: true,
    name: 'Sequoia Multi',
    slug: 'sequoia',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 61,
    enabled: true,
    name: 'Spring Health',
    slug: 'springhealth',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 62,
    enabled: true,
    name: 'Alcohol RCT',
    slug: 'alcohol-rct',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 63,
    enabled: true,
    name: 'Vroom',
    slug: 'vroom',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 64,
    enabled: true,
    name: 'Alcohol RCT Control',
    slug: 'alcohol-rct-control',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 66,
    enabled: true,
    name: 'Farm Bureau Home Office',
    slug: 'fbhp',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 67,
    enabled: true,
    name: 'Farm Bureau Health Plans',
    slug: 'fbhpmembers',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 91,
    enabled: true,
    name: 'Fiesta Mart',
    slug: 'fiestamart',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: true,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 97,
    enabled: true,
    name: 'U.S. Venture',
    slug: 'usventure',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 99,
    enabled: true,
    name: 'Southcoast Health',
    slug: 'southcoasthealth',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 100,
    enabled: true,
    name: 'K&L Gates',
    slug: 'klgates-uk',
    dataRegion: DataRegion.EU,
    country: Country.GB,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
  },
  {
    id: 103,
    enabled: true,
    name: 'Southern Company',
    slug: 'southerncompany',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: true,
    digitalOnboardingEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: false,
    },
  },
  {
    id: 104,
    enabled: true,
    name: 'Cambia',
    slug: 'cambia',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 106,
    enabled: true,
    name: 'Apree Health',
    slug: 'apreehealth',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 107,
    enabled: true,
    name: 'Live Nation',
    slug: 'livenation',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 109,
    enabled: true,
    name: 'RPM International',
    slug: 'rpm',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 113,
    enabled: true,
    name: 'KKR',
    slug: 'KKR',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 114,
    enabled: true,
    name: 'BD',
    slug: 'bd',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 115,
    enabled: true,
    name: 'CNH Industrial',
    slug: 'cnh',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    digitalOnboardingEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    // Represents non-medically enrolled RBS associates,
    // spouses/domestic partners & dependents ages 18 & up
    // AND Stop & Shop brand full population.
    // They are only eligible for Tobacco.
    id: 117,
    enabled: true,
    name: 'Retail Business Services',
    slug: 'mred',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    // Represents RBS associates, spouses/domestic partners & dependents ages 18 & up
    // enrolled in a Capital Blue Cross medical plan,
    // EXCEPT Stop & Shop brand full population.
    // They are eligible for all programs.
    id: 118,
    enabled: true,
    name: 'Retail Business Services',
    slug: 'mred',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 119,
    enabled: true,
    name: 'Marpai',
    slug: 'marpai',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 83,
    enabled: true,
    name: 'Chobani',
    slug: 'chobani',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 126,
    enabled: true,
    name: 'Curative',
    slug: 'curative',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    digitalOnboardingEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 127,
    enabled: true,
    name: 'IBX',
    slug: 'IBX',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: false,
      [FlowVersion.LighterV1]: false,
      [FlowVersion.HealthPlan]: true,
    },
  },
  {
    id: 128,
    enabled: true,
    name: 'AmeriHealth',
    slug: 'AmeriHealth',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: false,
      [FlowVersion.LighterV1]: false,
      [FlowVersion.HealthPlan]: true,
    },
  },
  {
    id: 123,
    enabled: true,
    name: 'MPI Alcohol',
    slug: 'mpi-alcoholcare',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: false,
      eCigarettes: false,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 129,
    enabled: true,
    name: 'Guardian',
    slug: 'guardian',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 131,
    enabled: true,
    name: 'Gray Television',
    slug: 'graytv',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 132,
    enabled: true,
    name: 'Wellstar',
    slug: 'wellstar',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: false,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: false,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: false,
    minorsProgramEnabled: false,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
  {
    id: 134,
    enabled: true,
    name: 'Amazon',
    slug: 'amazon',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: true,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
    benefitHasCost: true,
  },
  {
    id: 136,
    enabled: true,
    name: 'Cobalt Benefits Group',
    slug: 'cobalt',
    dataRegion: DataRegion.US,
    country: Country.US,
    spanishEnabled: false,
    requireSouthAfricaIDNumber: false,
    gbEthnicity: false,
    enabledTreatmentPrograms: {
      alcohol: true,
      cannabis: false,
      chewingTobacco: false,
      cigarettes: true,
      eCigarettes: true,
      opioids: true,
      cigarettesLearn: false,
      eCigarettesLearn: false,
      stimulants: false,
    },
    polyaddictionEnabled: true,
    minorsProgramEnabled: true,
    flowVersionsEnabled: {
      [FlowVersion.Original]: true,
      [FlowVersion.LighterV1]: true,
    },
  },
]
