import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Radio, RadioProps } from '@mui/material'
import { ReactElement } from 'react'

import './CardRadio.scss'

interface CardProps {
  imageSrc: string
  description: string
}

interface CardSelectorProps extends RadioProps, CardProps {}

/**
 * Component used as a replacement for icon in CardRadio comp
 * @param props
 * @returns
 */
function CardRadioCard(props: CardProps): ReactElement {
  const { imageSrc, description } = props

  return (
    <div className="card-radio-card">
      <img src={imageSrc} height="107px" width="185px" alt="" />
      <div className="card-radio-card__bottom-container">
        <div>{description}</div>
        <CheckCircleRoundedIcon className="card-radio-card__checked-icon" />
      </div>
    </div>
  )
}

/**
 * Custom implementation of Radio comp (card with image/text)
 * @param props
 * @returns
 */
export default function CardRadio(props: CardSelectorProps): ReactElement {
  const { imageSrc, description, ...radioProps } = props
  const cardEl = <CardRadioCard imageSrc={imageSrc} description={description} />

  return (
    <Radio
      className="card-radio"
      disableRipple
      checkedIcon={cardEl}
      icon={cardEl}
      inputProps={{ 'aria-label': description }}
      {...radioProps}
    />
  )
}
