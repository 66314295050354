import { pick } from 'lodash-es'

import { DataRegion } from '~constants/types'
import { apiClient } from '~services/apiClient'

interface GetServiceDirectoryResponse {
  success: boolean
  services: {
    apiBaseUrl: string
    apiKey: string
    region: string
    registrationClientId: string
    registrationPoolId: string
    registrationPoolRegion: string
  }
  loginPools: {
    region: string
    poolId: string
    clientId: string
  }[]
}

export interface Services {
  apiKey: string
  registrationClientId: string
  registrationPoolId: string
}

/**
 * Fetch the Cognito details and API key for a given data region
 */
export default async function getServiceDirectory(
  dataRegion: DataRegion
): Promise<Services> {
  const clientId = import.meta.env.VITE_APP_CLIENT_ID
  const domain = import.meta.env.VITE_SERVICE_DIRECTORY_DOMAIN
  const baseURL = `https://services-directory-${dataRegion}${domain}/clients`
  const response = await apiClient.get<GetServiceDirectoryResponse>(clientId, {
    baseURL,
  })
  return pick(response.data.services, [
    'apiKey',
    'registrationClientId',
    'registrationPoolId',
  ])
}
